<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        xl="6"
        offset-xl="3"
        md="8"
        offset-md="2"
      >
        <v-card
          class="mt-16 ma-6 pa-12"
          elevation="4"
        >
          <h2 class="py-6">
            Ihr Zugang ist inaktiv.
          </h2>
          Bitte wenden Sie sich an Ihren Ansprechpartner.
          <h4 class="py-6">
            Ihr {{ readablePlatformName }} Customer Success Team.
          </h4>

          <client-advisor :company="company" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import COMPANY_BASE from '../queries/CompanyBase.gql'
import ClientAdvisor from '@/components/ClientAdvisor.vue'
import brandingMixin from '@/mixins/branding'

export default {
  components: { ClientAdvisor },
  mixins: [brandingMixin],
  apollo: {
    company: {
      query: COMPANY_BASE,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      }
    }
  }
}
</script>
